.section_separator {
  border-bottom: 20px solid var(--kt-primary) !important;
}

@media only screen and (max-width: 600px) {
  .min-w-100px{
    min-width: none;
  }
  .min-w-65px{
    min-width: none;
  }
}

@font-face {
  font-family: vazir;
  font-style: normal;
  font-weight: 100;
  src: url("../fonts/Vazir-Thin.eot");
  src: url("../fonts/Vazir-Thin.woff") format("woff"),
    url("../fonts/Vazir-Thin.ttf") format("ttf"),
    url("../fonts/Vazir-Thin.woff2") format("woff2");
}
@font-face {
  font-family: vazir;
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/Vazir-Light.eot");
  src: url("../fonts/Vazir-Light.woff") format("woff"),
    url("../fonts/Vazir-Light.ttf") format("ttf"),
    url("../fonts/Vazir-Light.woff2") format("woff2");
}

@font-face {
  font-family: vazir;
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/Vazir.eot");
  src: url("../fonts/Vazir.woff") format("woff"),
    url("../fonts/Vazir.ttf") format("ttf"),
    url("../fonts/Vazir.woff2") format("woff2");
}
@font-face {
  font-family: vazir;
  font-style: normal;
  font-weight: 950;
  src: url("../fonts/Vazir-Bold.eot");
  src: url("../fonts/Vazir-Bold.woff") format("woff"),
    url("../fonts/Vazir-Bold.ttf") format("ttf"),
    url("../fonts/Vazir-Bold.woff2") format("woff2");
}

.font-Vazir {
  font-family: vazir !important;  
}
.font-small {  
  font-size: 10px;
}
.font-tiny {  
  font-size: 8px;
}
.font-thin {
  font-weight: 100;
}
.font-ltr {
  direction: ltr;
}

.thin-row{
  padding: 0px 0px !important
}